import logo from "./logo.png";
import "./App.css";

import { useEffect } from "react";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBWpX5BLCxMbgaXe815_9IOOMIo_wqvW48",
  authDomain: "jacob-s-wings.firebaseapp.com",
  projectId: "jacob-s-wings",
  storageBucket: "jacob-s-wings.appspot.com",
  messagingSenderId: "241965633396",
  appId: "1:241965633396:web:549e655648a150e0592621",
  measurementId: "G-7BEWCRVVKP",
};

function App() {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  useEffect(() => {
    logEvent(analytics, "landing_page_loaded");
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Jacob's Wings</p>
        <h2>
          Coming to St. Louis
          <br />
          Spring 2023
        </h2>
      </header>
    </div>
  );
}

export default App;
